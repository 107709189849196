import {
  MapContainer,
  TileLayer,
  GeoJSON,
  CircleMarker,
  LayersControl,
  WMSTileLayer,
  FeatureGroup,
  useMapEvent, // Import FeatureGroup
} from 'react-leaflet';
import { Feature, FeatureCollection, Polygon, Position } from 'geojson';
import L, { LatLng } from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import PentagonIcon from '@mui/icons-material/Pentagon';
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Container,
  Typography,
  Alert, // Import Alert
  CircularProgress, // Import CircularProgress
} from '@mui/material';

interface NewSiteProps {
  open: boolean;
  onClose: () => void;
  onCreate: (site: {
    name: string;
    address: string;
    site_manager: string;
    site_manager_phone: string;
    site_manager_email: string;
    geoJson: Feature;
  }) => Promise<void>;
}

const NewSite: React.FC<NewSiteProps> = ({ open, onClose, onCreate }) => {
  const [name, setName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [site_manager, setSiteManager] = React.useState('');
  const [site_manager_phone, setSiteManagerPhone] = React.useState('');
  const [site_manager_email, setSiteManagerEmail] = React.useState('');
  const [geoJson, setGeoJson] = React.useState<Feature | null>(null);

  const [nameError, setNameError] = React.useState('');
  const [addressError, setAddressError] = React.useState('');
  const [siteManagerError, setSiteManagerError] = React.useState('');
  const [siteManagerPhoneError, setSiteManagerPhoneError] = React.useState('');
  const [siteManagerEmailError, setSiteManagerEmailError] = React.useState('');
  const [geoJsonError, setGeoJsonError] = React.useState('');

  const [loading, setLoading] = React.useState(false); // Add loading state

  const handleCreate = async () => {
    setNameError('');
    setAddressError('');
    setSiteManagerError('');
    setSiteManagerPhoneError('');
    setSiteManagerEmailError('');
    setGeoJsonError('');

    if (!name) {
      setNameError('Please fill in the site name.');
      return;
    }
    if (!address) {
      setAddressError('Please fill in the site address.');
      return;
    }
    if (!site_manager) {
      setSiteManagerError('Please fill in the site manager.');
      return;
    }
    if (!site_manager_phone) {
      setSiteManagerPhoneError('Please fill in the site manager phone.');
      return;
    }
    if (!site_manager_email) {
      setSiteManagerEmailError('Please fill in the site manager email.');
      return;
    }
    if (!geoJson) {
      console.log('no geoJson');
      setGeoJsonError(
        'Please add a boundary for the site. Use the draw tool button:'
      );
      return;
    }

    setLoading(true); // Set loading to true when checks are satisfied

    // Simulate an async operation
    await onCreate({
      name,
      address,
      site_manager,
      site_manager_phone,
      site_manager_email,
      geoJson,
    });
    setName('');
    setAddress('');
    setSiteManager('');
    setSiteManagerPhone('');
    setSiteManagerEmail('');
    setGeoJson(null);
    setLoading(false); // Reset loading state after operation
  };

  const addPolygon = (e: any) => {
    const layer = e.layer;
    const coords: LatLng[][] = layer.getLatLngs();

    // Convert Leaflet LatLngs to GeoJSON coordinates

    function toPosition(latLng: LatLng): Position {
      return [latLng.lng, latLng.lat];
    }

    const geoJsonCoords = [coords[0].map(toPosition)];
    const feature: Feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: geoJsonCoords,
      },
      properties: {},
    };
    setGeoJson(feature);
  };

  const mapClick = (e: L.LeafletMouseEvent) => {
    const position: Position = [e.latlng.lng, e.latlng.lat];
    console.log(position);
  };

  const MapClickHandler = () => {
    useMapEvent('click', mapClick);
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      style={{ height: '90vh' }}
    >
      <DialogTitle>New Site</DialogTitle>
      <DialogContent style={{ height: '80vh' }}>
        <TextField
          autoFocus
          margin="dense"
          label="Site Name"
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
        />
        {nameError && (
          <Alert severity="error" style={{ marginTop: 8 }}>
            {nameError}
          </Alert>
        )}

        <TextField
          margin="dense"
          label="Site Address"
          fullWidth
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
        {addressError && (
          <Alert severity="error" style={{ marginTop: 8 }}>
            {addressError}
          </Alert>
        )}

        <TextField
          margin="dense"
          label="Site Manager"
          fullWidth
          value={site_manager}
          onChange={e => setSiteManager(e.target.value)}
        />
        {siteManagerError && (
          <Alert severity="error" style={{ marginTop: 8 }}>
            {siteManagerError}
          </Alert>
        )}

        <TextField
          margin="dense"
          label="Site Manager Phone"
          fullWidth
          value={site_manager_phone}
          onChange={e => setSiteManagerPhone(e.target.value)}
        />
        {siteManagerPhoneError && (
          <Alert severity="error" style={{ marginTop: 8 }}>
            {siteManagerPhoneError}
          </Alert>
        )}

        <TextField
          margin="dense"
          label="Site Manager Email"
          fullWidth
          value={site_manager_email}
          onChange={e => setSiteManagerEmail(e.target.value)}
        />
        {siteManagerEmailError && (
          <Alert severity="error" style={{ marginTop: 8 }}>
            {siteManagerEmailError}
          </Alert>
        )}

        <Container style={{ height: '100%', width: '100%' }}>
          <Typography variant="h6" style={{ fontSize: '0.8rem' }}>
            Site Boundary
          </Typography>
          <div
            id="map"
            style={{
              height: '100%',
              width: '100%',
              border: '3px solid #ccc',
            }}
          >
            <MapContainer
              center={[-33.808, 150.937]}
              zoom={7}
              style={{ height: '100%', width: '100%' }}
            >
              <MapClickHandler />
              <LayersControl position="topleft">
                <LayersControl.BaseLayer name="Map">
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer checked name="Satellite">
                  <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
                </LayersControl.BaseLayer>

                <FeatureGroup>
                  <EditControl
                    position="topleft"
                    onCreated={addPolygon}
                    draw={{
                      rectangle: false,
                      circle: false,
                      circlemarker: false,
                      marker: false,
                      polyline: false,
                    }}
                  />
                </FeatureGroup>
              </LayersControl>
            </MapContainer>
          </div>
        </Container>
      </DialogContent>
      {geoJsonError && (
        <Alert
          severity="error"
          style={{ marginTop: 8, padding: 8, marginLeft: 16, marginRight: 16 }}
        >
          {geoJsonError} <PentagonIcon style={{ fontSize: '1rem' }} />
        </Alert>
      )}
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null} // Add loading spinner
        >
          {loading ? 'Creating...' : 'Create Site'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewSite;
