import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Place, Info } from '@mui/icons-material';
import { useState } from 'react';

import axios from 'axios';
import { API_BASE_URL } from '../../utils/consts';

import { Questions } from './Questions';
import { OHSStatus } from './OHSStatus';

export const VisitForm: React.FC = () => {
  const [formData, setFormData] = useState({
    fullName: 'Bob Brown',
    phone: '484309555',
    email: 'bob.brown1977@gmail.com',
    // email: 'will.smith@exoflare.com',
    // email: 'jsmith12345@gmail.com',
    // email: 'rbailey@hsi.com',
    isEmployee: 'yes',
    company: 'ExoFlare',
    hostName: 'John Doe',
    reason:
      'Transport commodities to and from the site, including scheduled transport and delivery',
    exitTime: 'In 1 hour',
    agreeToTerms: false,
  });

  const [ohsStatusPage, setOHSStatusPage] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setOHSStatusPage(true);
  };

  return ohsStatusPage ? (
    <OHSStatus email={formData.email} />
  ) : (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography
        variant="h5"
        component="h1"
        sx={{ mb: 3, color: '#0066CC', textAlign: 'center' }}
      >
        VISIT DETAILS
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 4 }}>
        <Place color="action" />
        <Typography>
          Demo Chicken Hatchery |{' '}
          <Box component="span" sx={{ color: 'text.secondary' }}>
            +61 484 309 048
          </Box>
        </Typography>
      </Box>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
      >
        <TextField
          required
          fullWidth
          label="Full name"
          placeholder="Enter full name"
          value={formData.fullName}
          onChange={e => setFormData({ ...formData, fullName: e.target.value })}
        />

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField disabled value="+61" sx={{ width: '80px' }} />
          <TextField
            fullWidth
            label="Phone"
            placeholder="Enter phone"
            value={formData.phone}
            onChange={e => setFormData({ ...formData, phone: e.target.value })}
          />
        </Box>

        <TextField
          fullWidth
          label="Email"
          type="email"
          placeholder="Enter email"
          value={formData.email}
          onChange={e => setFormData({ ...formData, email: e.target.value })}
        />

        <FormControl required>
          <FormLabel>Are you a ExoFlare Demo employee?</FormLabel>
          <RadioGroup
            row
            value={formData.isEmployee}
            onChange={e =>
              setFormData({ ...formData, isEmployee: e.target.value })
            }
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        <TextField
          required
          fullWidth
          label="Company"
          placeholder="Enter company name"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  component="span"
                  sx={{ width: 24, height: 24, bgcolor: '#f0f0f0' }}
                />
              </InputAdornment>
            ),
          }}
          value={formData.company}
          onChange={e => setFormData({ ...formData, company: e.target.value })}
        />

        <TextField
          required
          fullWidth
          label="Host name"
          placeholder="Enter host name"
          value={formData.hostName}
          onChange={e => setFormData({ ...formData, hostName: e.target.value })}
        />

        <TextField
          required
          fullWidth
          label="Reason"
          placeholder="Enter reason"
          value={formData.reason}
          onChange={e => setFormData({ ...formData, reason: e.target.value })}
        />

        <FormControl fullWidth>
          <Select
            value={formData.exitTime}
            onChange={e =>
              setFormData({ ...formData, exitTime: e.target.value })
            }
            displayEmpty
            label="Exit Time"
          >
            <MenuItem value="In 1 hour">In 1 hour</MenuItem>
            <MenuItem value="In 2 hours">In 2 hours</MenuItem>
            <MenuItem value="In 4 hours">In 4 hours</MenuItem>
            <MenuItem value="In 8 hours">In 8 hours</MenuItem>
            <MenuItem value="End of day">End of day</MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1 }}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            These are the notifications you will receive. Adjust the visit date
            and time to preview changes.
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mb: 1 }}>
            <Info color="primary" />
            <Box>
              <Typography variant="body2" color="primary">
                Host approval or denial
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Sent after host has approved or denied your visit request
              </Typography>
            </Box>
          </Box>
          <Link
            href="#"
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}
          >
            <Info fontSize="small" />
            What are the different workflows?
          </Link>
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={formData.agreeToTerms}
              onChange={e =>
                setFormData({ ...formData, agreeToTerms: e.target.checked })
              }
            />
          }
          label={
            <Typography variant="body2">
              I agree to ExoFlare's{' '}
              <Link href="#" color="primary">
                Terms & Conditions
              </Link>{' '}
              and{' '}
              <Link href="#" color="primary">
                Privacy Policy
              </Link>
            </Typography>
          }
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          onClick={handleSubmit}
          disabled={!formData.agreeToTerms}
          sx={{ mt: 2 }}
        >
          REQUEST VISIT
        </Button>
      </Box>
    </Container>
  );
};
