import axios from 'axios';
import Map from './Map';
import { Feature, FeatureCollection, Position } from 'geojson';
import { useEffect, useState } from 'react';
import { API_BASE_URL, SessionManager } from '../../utils/consts';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { centroid, polygon, center } from '@turf/turf';

type FireantsData = {
  fireants: FeatureCollection;
};

interface FireantsDashboardProps {
  sessionManager: SessionManager;
}

const FireantsDashboard: React.FC<FireantsDashboardProps> = ({
  sessionManager,
}) => {
  const [data, setData] = useState<FireantsData | null>(null);
  const [showPaddocks, setShowPaddocks] = useState(true);
  const [analyzing, setAnalyzing] = useState(false);
  const [analysingArea, setAnalysingArea] = useState<Feature | null>(null);
  const [numCows, setNumCows] = useState<number | null>(null);

  const [fireants, setFireants] = useState<FeatureCollection | null>(null);
  const [showFireants, setShowFireants] = useState(true);

  const [squareFeature, setSquareFeature] = useState<Feature | null>(null);
  const [fromAddress, setFromAddress] = useState<string>(
    '7-1 Kirkstall St, Holmview QLD 4207'
  );

  const [toAddress, setToAddress] = useState<string>('');

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseData = await sessionManager.getData('fireants_data');
      console.log('responseData', responseData);
      if (responseData) {
        setData(responseData);
        setFireants(responseData);
        console.log('fireants dashboard', fireants);
      }
    } catch (error) {
      console.error('Error fetching fireants data:', error);
    }
  };

  function getRoute() {
    console.log('getRoute');
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: '300px',
          padding: '10px',
          borderRight: '1px solid #ccc',
        }}
      >
        <h3>Legend</h3>
        <h4>Data Points</h4>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {fireants && (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: 'blue',
                    marginRight: '10px',
                  }}
                ></div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showFireants}
                      onChange={() => setShowFireants(!showFireants)}
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: 'blue',
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                          {
                            backgroundColor: 'blue',
                          },
                      }}
                    />
                  }
                  label="Fireants EAD Zones"
                />
              </div>
            </div>
          )}
          <div style={{ marginTop: '20px' }}>
            <TextField
              label="From Address"
              value={fromAddress}
              onChange={(e: any) => setFromAddress(e.target.value)}
              sx={{ marginBottom: '20px' }}
            />

            <TextField
              label="To Address"
              value={toAddress}
              onChange={(e: any) => setToAddress(e.target.value)}
            />

            <div style={{ marginTop: '10px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  getRoute();
                }}
              >
                {analyzing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Check Route'
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        {data ? (
          <Map
            sessionManager={sessionManager}
            fireants={showFireants ? fireants : null}
          />
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
};

export default FireantsDashboard;
