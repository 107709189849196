import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const BMPList = () => {
  const navigate = useNavigate();

  return (
    <Stack spacing={3} sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
      <Typography variant="h4" component="h1" textAlign="center">
        Biosecurity Management Plans
      </Typography>

      <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
        Select your state to complete the relevant Biosecurity Management Plan.
        Currently, only the Victorian BMP form is available. Other state forms
        will be added soon.
      </Typography>

      <Button
        variant="contained"
        size="large"
        fullWidth
        sx={{
          padding: 2,
          fontSize: '1.2rem',
          textTransform: 'none',
        }}
        onClick={() => navigate('/bmps/victoria')}
      >
        Victoria Biosecurity Management Plan
      </Button>

      <Button
        variant="contained"
        size="large"
        fullWidth
        disabled
        sx={{
          padding: 2,
          fontSize: '1.2rem',
          textTransform: 'none',
        }}
      >
        New South Wales Biosecurity Management Plan
      </Button>

      <Button
        variant="contained"
        size="large"
        fullWidth
        disabled
        sx={{
          padding: 2,
          fontSize: '1.2rem',
          textTransform: 'none',
        }}
      >
        Queensland Biosecurity Management Plan
      </Button>

      <Button
        variant="contained"
        size="large"
        fullWidth
        disabled
        sx={{
          padding: 2,
          fontSize: '1.2rem',
          textTransform: 'none',
        }}
      >
        Western Australia Biosecurity Management Plan
      </Button>

      <Button
        variant="contained"
        size="large"
        fullWidth
        disabled
        sx={{
          padding: 2,
          fontSize: '1.2rem',
          textTransform: 'none',
        }}
      >
        South Australia Biosecurity Management Plan
      </Button>

      <Button
        variant="contained"
        size="large"
        fullWidth
        disabled
        sx={{
          padding: 2,
          fontSize: '1.2rem',
          textTransform: 'none',
        }}
      >
        Tasmania Biosecurity Management Plan
      </Button>

      <Button
        variant="contained"
        size="large"
        fullWidth
        disabled
        sx={{
          padding: 2,
          fontSize: '1.2rem',
          textTransform: 'none',
        }}
      >
        Northern Territory Biosecurity Management Plan
      </Button>

      <Button
        variant="contained"
        size="large"
        fullWidth
        disabled
        sx={{
          padding: 2,
          fontSize: '1.2rem',
          textTransform: 'none',
        }}
      >
        Australian Capital Territory Biosecurity Management Plan
      </Button>
    </Stack>
  );
};

export default BMPList;
