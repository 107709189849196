import { FeatureCollection } from 'geojson';
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import { API_BASE_URL } from '../../utils/consts';
import axios from 'axios';
import L from 'leaflet';

interface FormMapProps {
  initialGeoJson: FeatureCollection | null;
}

const FormMap: React.FC<FormMapProps> = ({ initialGeoJson }) => {
  const [mapCenter, setMapCenter] = useState<[number, number]>([
    -34.46, 149.352,
  ]);
  const [zoom, setZoom] = useState<number>(7);

  const FitBounds: React.FC<{ geoJson: FeatureCollection }> = ({ geoJson }) => {
    const map = useMap();

    useEffect(() => {
      if (geoJson && geoJson.features.length > 0) {
        const geoJsonLayer = L.geoJSON(geoJson);
        const bounds = geoJsonLayer.getBounds();
        map.fitBounds(bounds, { padding: [50, 50] });
      }
    }, [geoJson, map]);

    return null;
  };

  return (
    <MapContainer
      center={mapCenter}
      zoom={zoom}
      style={{
        height: '600px',
        width: '100%',
        border: '4px solid darkgreen',
      }}
      zoomControl={false}
      dragging={false}
      scrollWheelZoom={false}
    >
      <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />

      {initialGeoJson && (
        <>
          <FitBounds geoJson={initialGeoJson} />
          <GeoJSON
            key={JSON.stringify(initialGeoJson)}
            data={initialGeoJson}
            style={{ color: 'red' }}
          />
        </>
      )}
    </MapContainer>
  );
};

export default FormMap;
