import {
  MapContainer,
  TileLayer,
  GeoJSON,
  CircleMarker,
  LayersControl,
  WMSTileLayer,
  FeatureGroup,
  useMapEvent, // Import FeatureGroup
} from 'react-leaflet';
import { Feature, FeatureCollection, Point, Polygon, Position } from 'geojson';
import L, { LatLng } from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import PentagonIcon from '@mui/icons-material/Pentagon';
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Container,
  Typography,
  Alert, // Import Alert
  CircularProgress, // Import CircularProgress
} from '@mui/material';

interface NewSiteProps {
  open: boolean;
  onClose: () => void;
  onCreate: (geoJson: Feature) => Promise<void>;
}

const NewSite: React.FC<NewSiteProps> = ({ open, onClose, onCreate }) => {
  const [geoJson, setGeoJson] = React.useState<Feature | null>(null);
  const [points, setPoints] = React.useState<Feature[]>([]); // Add state for points
  const [geoJsonError, setGeoJsonError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleCreate = async () => {
    setGeoJsonError('');

    if (!geoJson) {
      console.log('no geoJson');
      setGeoJsonError(
        'Please add a boundary for the site. Use the draw tool button:'
      );
      return;
    }

    setLoading(true);

    // Create a FeatureCollection containing both the polygon and points
    const featureCollection: Feature = {
      type: 'Feature',
      geometry: {
        type: 'GeometryCollection',
        geometries: [geoJson.geometry, ...points.map(point => point.geometry)],
      },
      properties: {},
    };

    await onCreate(featureCollection);
    setGeoJson(null);
    setPoints([]); // Clear points
    setLoading(false);
  };

  const addPolygon = (e: any) => {
    const layer = e.layer;
    const coords: LatLng[][] = layer.getLatLngs();

    function toPosition(latLng: LatLng): Position {
      return [latLng.lng, latLng.lat];
    }

    const geoJsonCoords = [coords[0].map(toPosition)];
    const feature: Feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: geoJsonCoords,
      },
      properties: {},
    };
    setGeoJson(feature);
  };

  // Add handler for point creation
  const addPoint = (e: any) => {
    const layer = e.layer;
    const latLng: LatLng = layer.getLatLng();

    const feature: Feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [latLng.lng, latLng.lat],
      },
      properties: {},
    };

    setPoints(prevPoints => [...prevPoints, feature]);
  };

  const mapClick = (e: L.LeafletMouseEvent) => {
    const position: Position = [e.latlng.lng, e.latlng.lat];
    console.log(position);
  };

  const MapClickHandler = () => {
    useMapEvent('click', mapClick);
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      style={{ height: '90vh' }}
    >
      <DialogTitle>New Site</DialogTitle>
      <DialogContent style={{ height: '80vh' }}>
        <Container style={{ height: '100%', width: '100%' }}>
          <Typography variant="h6" style={{ fontSize: '0.8rem' }}>
            Site Boundary and Access Points
          </Typography>
          <div
            id="map"
            style={{
              height: '100%',
              width: '100%',
              border: '3px solid #ccc',
            }}
          >
            <MapContainer
              center={[-33.808, 150.937]}
              zoom={7}
              style={{ height: '100%', width: '100%' }}
            >
              <MapClickHandler />
              <LayersControl position="topleft">
                <LayersControl.BaseLayer name="Map">
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer checked name="Satellite">
                  <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
                </LayersControl.BaseLayer>

                <FeatureGroup>
                  <EditControl
                    position="topleft"
                    onCreated={e => {
                      if (e.layerType === 'marker') {
                        addPoint(e);
                      } else if (e.layerType === 'polygon') {
                        addPolygon(e);
                      }
                    }}
                    draw={{
                      rectangle: false,
                      circle: false,
                      circlemarker: false,
                      polyline: false,
                      polygon: true,
                      marker: true, // Enable marker drawing
                    }}
                  />
                  {/* Render existing points if needed */}
                  {points.map((point, index) => {
                    const coords = (point.geometry as Point).coordinates;
                    return (
                      <CircleMarker
                        key={index}
                        center={[coords[1], coords[0]]}
                        radius={8}
                        color="red"
                      />
                    );
                  })}
                </FeatureGroup>
              </LayersControl>
            </MapContainer>
          </div>
        </Container>
      </DialogContent>
      {geoJsonError && (
        <Alert
          severity="error"
          style={{ marginTop: 8, padding: 8, marginLeft: 16, marginRight: 16 }}
        >
          {geoJsonError} <PentagonIcon style={{ fontSize: '1rem' }} />
        </Alert>
      )}
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {loading ? 'Creating...' : 'Create Site'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewSite;
