// src/components/Subscribe.tsx
import React, { useState } from 'react';
import {
  TextField,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  AppBar,
  Toolbar,
} from '@mui/material';
import { LocalShipping, Person, Place, QrCode2 } from '@mui/icons-material';
import { VisitForm } from './VisitForm';

export const Checkin: React.FC = () => {
  const [showForm, setShowForm] = useState(false);

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {showForm ? (
        <VisitForm />
      ) : (
        <Container maxWidth="md" sx={{ mt: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <Place color="action" />
            <Typography>
              Demo Chicken Hatchery |{' '}
              <Box component="span" sx={{ color: 'text.secondary' }}>
                +61 484 309 048
              </Box>
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 4,
            }}
          >
            <Typography variant="h3" component="h1">
              Check-in
            </Typography>
            <Button variant="contained" color="primary">
              SIGN OUT
            </Button>
          </Box>

          <Typography variant="h6" sx={{ mb: 2 }}>
            Select how you'd like to check in
          </Typography>

          <Card
            sx={{ mb: 2, cursor: 'pointer', '&:hover': { bgcolor: '#f8f8f8' } }}
          >
            <CardContent sx={{ display: 'flex', gap: 2 }}>
              <LocalShipping color="primary" sx={{ fontSize: 40 }} />
              <Box>
                <Typography variant="h6">Delivery / Transport</Typography>
                <Typography color="text.secondary">
                  Transport commodities to and from the site, including
                  scheduled transport and delivery
                </Typography>
              </Box>
            </CardContent>
          </Card>

          <Card
            sx={{ mb: 4, cursor: 'pointer', '&:hover': { bgcolor: '#f8f8f8' } }}
          >
            <CardContent
              sx={{ display: 'flex', gap: 2 }}
              onClick={() => setShowForm(true)}
            >
              <Person color="primary" sx={{ fontSize: 40 }} />
              <Box>
                <Typography variant="h6">Visitor</Typography>
                <Typography color="text.secondary">
                  Visit to production or office/amenities areas
                </Typography>
              </Box>
            </CardContent>
          </Card>

          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', mb: 4 }}>
            <QrCode2 sx={{ fontSize: 120 }} />
            <Box>
              <Typography variant="h6">Or scan to check-in</Typography>
              <Typography color="text.secondary">
                Use the camera app on your phone and scan the QR code to
                check-in
              </Typography>
            </Box>
          </Box>

          <Card
            sx={{
              bgcolor: 'white',
              color: '#f44336',
              textAlign: 'center',
              p: 2,
              border: '1px solid #f44336',
            }}
          >
            <Typography variant="h5" sx={{ mb: 1 }}>
              VISITORS
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              PLEASE RESPECT
            </Typography>
            <Typography variant="h5" sx={{ mb: 2 }}>
              FARM BIOSECURITY
            </Typography>
            <Typography sx={{ mb: 1 }}>
              For urgent matters, please contact
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              +61 484 309 048
            </Typography>
            <Typography sx={{ color: '#f44336' }}>
              Do not enter property without prior approval
            </Typography>
            <Typography sx={{ color: '#f44336' }}>
              Vehicles, people and equipment can carry weed seeds, pests and
              diseases
            </Typography>
          </Card>
        </Container>
      )}
    </Box>
  );
};
