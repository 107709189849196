import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  WMSTileLayer,
  Marker,
} from 'react-leaflet';
import { Feature, FeatureCollection } from 'geojson';
import L from 'leaflet';
import { useEffect, useState } from 'react';
import { SessionManager } from '../../utils/consts';

interface MapProps {
  sessionManager: SessionManager;
  fireants: FeatureCollection | null;
}

export interface ProfileProps {
  email: string;
  id: number;
  name: string;
  phone_number: string;
  address: string;
  location: string;
}

const Map: React.FC<MapProps> = ({ sessionManager, fireants }) => {
  const [profile, setProfile] = useState<ProfileProps | null>(null);
  const getProfile = async () => {
    const responseData = await sessionManager.getData('profile');
    setProfile(responseData);
  };

  useEffect(() => {
    console.log('fireants map', fireants);
  }, []);

  const onPolygonClick = (feature: Feature, event: L.LeafletMouseEvent) => {
    console.log('Polygon clicked:', feature);
    console.log('Event:', event);
  };

  return (
    <div
      id="map"
      style={{ height: '100vh', width: '100vw', position: 'absolute' }}
    >
      <MapContainer
        center={[-27.991516821179324, 153.3152646656252]}
        zoom={11}
        style={{ height: '100%', width: '100%' }}
      >
        <LayersControl position="topleft">
          <LayersControl.BaseLayer name="Map">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked name="Satellite">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            />
          </LayersControl.BaseLayer>

          {/* <Marker position={[-27.708902889984955, 153.17872672771333]} /> */}

          {fireants && (
            <GeoJSON
              data={fireants}
              style={{
                weight: 3,
                opacity: 1,
                color: 'red',
              }}
              onEachFeature={(feature, layer) => {
                layer.on({
                  click: (event: L.LeafletMouseEvent) =>
                    onPolygonClick(feature, event),
                });
              }}
            />
          )}
        </LayersControl>
      </MapContainer>
    </div>
  );
};

export default Map;
