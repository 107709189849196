import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Container,
  Button,
} from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../../utils/consts';
import { Questions } from './Questions';

type Status = {
  status: string;
  color: string;
  contractor_message: string;
  company_message: string;
};

type DonesafeStatus = {
  status: string;
  user_id: number | null;
  company_status: string;
  company_name: string | null;
};

const statuses: Status[] = [
  {
    status: 'Expired',
    color: '#f44336',
    contractor_message:
      'Your OH&S status has expired. Please contact the site manager and update your OH&S documents.',
    company_message:
      'Your company OH&S status has expired. This may impact your ability to access the site.',
  },
  {
    status: 'Current',
    color: '#4CAF50',
    contractor_message: 'Your OH&S status and documents are current.',
    company_message: 'Your company OH&S status and documents are current.',
  },
  {
    status: 'Outstanding',
    color: '#FF9800',
    contractor_message:
      'Your OH&S status is outstanding. Please contact the site manager and update your OH&S documents.',
    company_message:
      'Your company OH&S status is outstanding. This may impact your ability to access the site.',
  },
  {
    status: 'Unregistered',
    color: '#606060',
    contractor_message:
      'You are not registered with OH&S for this site. Please contact the site manager and update your OH&S documents.',
    company_message:
      'Your company is not registered with OH&S for this site. This may impact your ability to access the site.',
  },
  {
    status: 'Error',
    color: '#f44336',
    contractor_message: 'An error occurred while checking your OH&S status.',
    company_message:
      'An error occurred while checking your company OH&S status.',
  },
];

interface OHSStatusProps {
  email: string;
}

export const OHSStatus: React.FC<OHSStatusProps> = ({ email }) => {
  const [ohsStatusDetails, setOHSStatusDetails] = useState<Status | null>(null);
  const [ohsStatusLoading, setOHSStatusLoading] = useState(false);
  const [companyStatusDetails, setCompanyStatusDetails] =
    useState<DonesafeStatus | null>(null);
  const [statusBoxColor, setStatusBoxColor] = useState<string>('#4CAF50');
  const [statusMessage, setStatusMessage] = useState<string>(
    'Thank you, your induction status has been validated. Please proceed to your biosecurity risk assessment'
  );

  const [canProceed, setCanProceed] = useState(false);

  const [showQuestions, setShowQuestions] = useState(false);

  useEffect(() => {
    donesafeStatus();
  }, [email]);

  async function donesafeStatus() {
    setOHSStatusDetails(null);
    setOHSStatusLoading(true);

    try {
      const response = await axios.get(
        `${API_BASE_URL}/public/donesafe_status`,
        {
          params: { email },
        }
      );

      const donesafeStatus: DonesafeStatus = response.data;
      setCompanyStatusDetails(donesafeStatus);

      setOHSStatusDetails(
        statuses.find(status => status.status === donesafeStatus.status) || null
      );

      if (
        donesafeStatus.status === 'Current' &&
        donesafeStatus.company_status === 'Current'
      ) {
        setStatusBoxColor('#4CAF50');
        setCanProceed(true);
      } else if (
        donesafeStatus.status === 'Current' &&
        donesafeStatus.company_status !== 'Current'
      ) {
        setStatusBoxColor('#FF9800');
        setStatusMessage(
          'Your company has ' +
            donesafeStatus.company_status +
            ' documentation on Donesafe'
        );
        setCanProceed(true);
      } else {
        setStatusBoxColor('#f44336');
        setStatusMessage(
          'You need to update your OH&S documentation on Donesafe. '
        );
        setCanProceed(false);
      }
    } catch (error: any) {
      console.error('Error checking OH&S status:', error);
      if (error.response?.status === 500) {
        setStatusBoxColor('#f44336');
        setStatusMessage('This site is not properly set up with Donesafe.');
        setCompanyStatusDetails({
          status: '',
          user_id: null,
          company_status: '',
          company_name: null,
        });
        setOHSStatusDetails(statuses[4]);
      }
    } finally {
      setOHSStatusLoading(false);
    }
  }

  if (ohsStatusLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!ohsStatusDetails) return null;

  return (
    <>
      {!showQuestions ? (
        <Container maxWidth="sm" sx={{ mt: 4 }}>
          <Box
            sx={{
              bgcolor: '#f8f9fa',
              border: `2px solid ${statusBoxColor}`,
              p: 3,
              borderRadius: 2,
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              color: 'text.primary',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2, color: statusBoxColor }}>
              OH&S Status Summary
            </Typography>

            <Typography variant="body2" sx={{ mb: 2, color: statusBoxColor }}>
              {statusMessage}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', mb: 0.5 }}
                >
                  Individual Status:{' '}
                  <Box component="span" sx={{ color: ohsStatusDetails.color }}>
                    {ohsStatusDetails.status}
                  </Box>
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {ohsStatusDetails.contractor_message}
                </Typography>
              </Box>

              <Box
                sx={{ pt: 2, borderTop: '1px solid', borderColor: 'divider' }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', mb: 0.5 }}
                >
                  Company Status:{' '}
                  <Box
                    component="span"
                    sx={{
                      color: statuses.find(
                        s => s.status === companyStatusDetails?.company_status
                      )?.color,
                    }}
                  >
                    {companyStatusDetails?.company_status}
                  </Box>
                </Typography>
                <Typography variant="body2">
                  {companyStatusDetails?.company_name}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {
                    statuses.find(
                      s => s.status === companyStatusDetails?.company_status
                    )?.company_message
                  }
                </Typography>
              </Box>
            </Box>
          </Box>

          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            sx={{ mt: 2 }}
            disabled={!canProceed}
            onClick={() => setShowQuestions(true)}
          >
            PROCEED
          </Button>
        </Container>
      ) : (
        <Questions />
      )}
    </>
  );
};
