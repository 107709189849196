import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Box,
  Fab,
  Container,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { createTheme, ThemeProvider } from '@mui/material';
import FormMap from './formMap';
import { useState } from 'react';
import NewSite from './NewSite';
import { Feature, FeatureCollection } from 'geojson';

// Initial form state with empty values
const INITIAL_FORM_DATA = {
  propertyName: '',
  propertyAddress: '',
  pic: '',
  nominatedPerson: { name: '', phone: '', email: '' },
  livestockManager: { name: '', phone: '', email: '', address: '' },
  additionalManagers: '',
  bmpStartDate: '',
  reviewDate: '',
  completedBy: '',
  localOfficer: { name: '', phone: '', email: '' },
  veterinarian: { name: '', phone: '', email: '', signature: '' },
};

// Define the type for formData
type FormData = typeof INITIAL_FORM_DATA;

// Add these helper functions at the top of your component
const formatDateForInput = (dateStr: string) => {
  if (!dateStr) return '';
  const [day, month, year] = dateStr.split('/');
  return day && month && year ? `${year}-${month}-${day}` : '';
};

const formatDateForDisplay = (dateStr: string) => {
  if (!dateStr) return '';
  const [year, month, day] = dateStr.split('-');
  return day && month && year ? `${day}/${month}/${year}` : '';
};

export default function BiosecurityForm() {
  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@media print': {
            body: {
              backgroundColor: 'white',
            },
            '.MuiPaper-root': {
              boxShadow: 'none !important',
              margin: '0 !important',
            },
            '@page': {
              size: 'A4',
              margin: 0,
              marks: 'none',
            },
          },
        },
      },
    },
  });

  // Load saved data or use initial empty state
  const [formData, setFormData] = useState<FormData>(() => {
    const saved = localStorage.getItem('biosecurityFormData');
    return saved ? JSON.parse(saved) : INITIAL_FORM_DATA;
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [savedGeoJson, setSavedGeoJson] = useState<FeatureCollection | null>(
    () => {
      const saved = localStorage.getItem('biosecurityFormGeoJson');
      return saved ? JSON.parse(saved) : null;
    }
  );

  const handleCreateSite = async (geoJson: Feature) => {
    // Convert the Feature to a FeatureCollection before saving
    const featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [geoJson],
    };

    console.log('Creating site:', featureCollection);
    localStorage.setItem(
      'biosecurityFormGeoJson',
      JSON.stringify(featureCollection)
    );
    setSavedGeoJson(featureCollection);
    handleClose();
  };

  const handleNestedChange = (
    category: keyof FormData,
    field: string,
    value: string
  ) => {
    setFormData(prev => {
      const newData = {
        ...prev,
        [category]: {
          ...(prev[category] as object),
          [field]: value,
        },
      };
      localStorage.setItem('biosecurityFormData', JSON.stringify(newData));
      return newData;
    });
  };

  const handleChange = (field: string, value: string) => {
    setFormData(prev => {
      const newData = { ...prev, [field]: value };
      localStorage.setItem('biosecurityFormData', JSON.stringify(newData));
      return newData;
    });
  };

  const handlePrint = () => {
    const printContent = document.createElement('div');
    printContent.innerHTML =
      document.querySelector('[data-biosecurity-form]')?.innerHTML || '';

    const originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent.innerHTML;

    // Set the filename based on property name
    const filename = formData.propertyName
      ? `${formData.propertyName.replace(/\s+/g, '_')}_biosecurity_plan.pdf`
      : 'biosecurity_plan.pdf';

    const style = document.createElement('style');
    style.innerHTML = `@page { size: A4; margin: 0; }`;
    document.head.appendChild(style);

    window.document.title = filename;
    window.print();
    window.location.reload();
  };

  return (
    <Container>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            width: '210mm',
            margin: 'auto',
            position: 'relative',
            '@media print': {
              margin: 0,
              width: '210mm',
            },
          }}
          data-biosecurity-form
        >
          {/* Page 1 */}
          <Paper
            sx={{
              width: '210mm',
              height: '297mm',
              padding: 0,
              marginBottom: '20px',
              '@media print': {
                marginBottom: 0,
                breakAfter: 'page',
                borderRadius: 0,
              },
            }}
          >
            <Box sx={{ p: '10mm' }}>
              <Typography
                variant="h4"
                color="darkgreen"
                textAlign="center"
                gutterBottom
                sx={{ fontWeight: 'bold' }}
              >
                BIOSECURITY MANAGEMENT PLAN
              </Typography>

              <Box sx={{ border: '1px solid black', p: 1, mb: 2 }}>
                <input
                  type="text"
                  value={formData.propertyAddress}
                  onChange={e =>
                    handleChange('propertyAddress', e.target.value)
                  }
                  placeholder="[insert property address here (mandatory)]"
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    color: 'darkgreen',
                    border: 'none',
                    background: 'transparent',
                    fontSize: 'inherit',
                  }}
                />
              </Box>

              <Typography variant="body2" paragraph align="left" sx={{ mb: 1 }}>
                This Biosecurity Management Plan is developed in accordance with
                the <i>Livestock Management Act 2010</i> (the Act) and the
                Livestock Management Regulations 2021. Failure to comply with
                the prescribed biosecurity measures may be an offence under the
                Act.
              </Typography>

              <Typography variant="body2" align="left" sx={{ mb: 1 }}>
                A visitor must{' '}
                <span style={{ color: 'black', textDecoration: 'underline' }}>
                  contact the nominated person
                </span>{' '}
                before entering to discuss their obligations.
              </Typography>

              <TableContainer>
                <Table sx={{ border: '4px solid darkgreen' }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          width: '50%',
                          border: '1px solid black',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Property Name:
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                        }}
                      >
                        <input
                          type="text"
                          value={formData.propertyName}
                          onChange={e =>
                            handleChange('propertyName', e.target.value)
                          }
                          style={{
                            width: '100%',
                            padding: '0px',
                            border: 'none',
                            background: 'transparent',
                            fontSize: 'inherit',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Property Identification Code (PIC):
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                        }}
                      >
                        <input
                          type="text"
                          value={formData.pic}
                          onChange={e => handleChange('pic', e.target.value)}
                          style={{
                            width: '100%',
                            padding: '0px',
                            border: 'none',
                            background: 'transparent',
                            fontSize: 'inherit',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Nominated person/s contact details:
                        <Typography variant="caption" display="block">
                          (mandatory)
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 0,
                            flexDirection: 'column',
                          }}
                        >
                          <input
                            type="text"
                            value={formData.nominatedPerson.name}
                            onChange={e =>
                              handleNestedChange(
                                'nominatedPerson',
                                'name',
                                e.target.value
                              )
                            }
                            placeholder="Name"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                          <input
                            type="text"
                            value={formData.nominatedPerson.phone}
                            onChange={e =>
                              handleNestedChange(
                                'nominatedPerson',
                                'phone',
                                e.target.value
                              )
                            }
                            placeholder="Phone"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                          <input
                            type="email"
                            value={formData.nominatedPerson.email}
                            onChange={e =>
                              handleNestedChange(
                                'nominatedPerson',
                                'email',
                                e.target.value
                              )
                            }
                            placeholder="Email"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Livestock manager/s contact details:
                        <Typography variant="caption" display="block">
                          (owner &/or manager &/or agent)
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 0,
                            flexDirection: 'column',
                          }}
                        >
                          <input
                            type="text"
                            value={formData.livestockManager.name}
                            onChange={e =>
                              handleNestedChange(
                                'livestockManager',
                                'name',
                                e.target.value
                              )
                            }
                            placeholder="Name"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                          <input
                            type="text"
                            value={formData.livestockManager.phone}
                            onChange={e =>
                              handleNestedChange(
                                'livestockManager',
                                'phone',
                                e.target.value
                              )
                            }
                            placeholder="Phone"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                          <input
                            type="email"
                            value={formData.livestockManager.email}
                            onChange={e =>
                              handleNestedChange(
                                'livestockManager',
                                'email',
                                e.target.value
                              )
                            }
                            placeholder="Email"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                          <input
                            type="text"
                            value={formData.livestockManager.address}
                            onChange={e =>
                              handleNestedChange(
                                'livestockManager',
                                'address',
                                e.target.value
                              )
                            }
                            placeholder="Address"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Additional livestock managers:
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                        }}
                      >
                        <input
                          type="text"
                          value={formData.additionalManagers}
                          onChange={e =>
                            handleChange('additionalManagers', e.target.value)
                          }
                          style={{
                            width: '100%',
                            padding: '0px',
                            border: 'none',
                            background: 'transparent',
                            fontSize: 'inherit',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Date that BMP comes into operation:
                        <Typography variant="caption" display="block">
                          (mandatory)
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                        }}
                      >
                        <input
                          type="date"
                          value={formatDateForInput(formData.bmpStartDate)}
                          onChange={e =>
                            handleChange(
                              'bmpStartDate',
                              formatDateForDisplay(e.target.value)
                            )
                          }
                          style={{
                            width: '100%',
                            padding: '0px',
                            border: 'none',
                            background: 'transparent',
                            fontSize: 'inherit',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Review date:
                        <Typography variant="caption" display="block">
                          (recommended every 12 months)
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                        }}
                      >
                        <input
                          type="date"
                          value={formatDateForInput(formData.reviewDate)}
                          onChange={e =>
                            handleChange(
                              'reviewDate',
                              formatDateForDisplay(e.target.value)
                            )
                          }
                          style={{
                            width: '100%',
                            padding: '0px',
                            border: 'none',
                            background: 'transparent',
                            fontSize: 'inherit',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        BMP completed by:
                        <Typography variant="caption" display="block">
                          (mandatory)
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                        }}
                      >
                        <input
                          type="text"
                          value={formData.completedBy}
                          onChange={e =>
                            handleChange('completedBy', e.target.value)
                          }
                          style={{
                            width: '100%',
                            padding: '0px',
                            border: 'none',
                            background: 'transparent',
                            fontSize: 'inherit',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Local Animal Health Officer:
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 0,
                            flexDirection: 'column',
                          }}
                        >
                          <input
                            type="text"
                            value={formData.localOfficer.name}
                            onChange={e =>
                              handleNestedChange(
                                'localOfficer',
                                'name',
                                e.target.value
                              )
                            }
                            placeholder="Name"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                          <input
                            type="text"
                            value={formData.localOfficer.phone}
                            onChange={e =>
                              handleNestedChange(
                                'localOfficer',
                                'phone',
                                e.target.value
                              )
                            }
                            placeholder="Phone"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                          <input
                            type="email"
                            value={formData.localOfficer.email}
                            onChange={e =>
                              handleNestedChange(
                                'localOfficer',
                                'email',
                                e.target.value
                              )
                            }
                            placeholder="Email"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Veterinarian:
                        <Typography variant="caption" display="block">
                          (Signature required for certain programs)
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 0,
                            flexDirection: 'column',
                          }}
                        >
                          <input
                            type="text"
                            value={formData.veterinarian.name}
                            onChange={e =>
                              handleNestedChange(
                                'veterinarian',
                                'name',
                                e.target.value
                              )
                            }
                            placeholder="Name"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                          <input
                            type="text"
                            value={formData.veterinarian.phone}
                            onChange={e =>
                              handleNestedChange(
                                'veterinarian',
                                'phone',
                                e.target.value
                              )
                            }
                            placeholder="Phone"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                          <input
                            type="email"
                            value={formData.veterinarian.email}
                            onChange={e =>
                              handleNestedChange(
                                'veterinarian',
                                'email',
                                e.target.value
                              )
                            }
                            placeholder="Email"
                            style={{
                              width: '100%',
                              padding: '0px',
                              border: 'none',
                              background: 'transparent',
                              fontSize: 'inherit',
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Emergency Animal Disease (EAD) Hotline:
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                          color: 'red',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: '14px',
                        }}
                      >
                        1800 675 888
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Victoria Police:
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                          fontWeight: 'bold',
                        }}
                      >
                        <Typography
                          sx={{
                            color: 'red',
                            textAlign: 'center',
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          000 (Emergency)
                        </Typography>
                        <Typography
                          sx={{
                            color: 'red',
                            textAlign: 'center',
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          131 444 (Assistance)
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>

          {/* Page 2 */}
          <Paper
            sx={{
              width: '210mm',
              height: '297mm',
              padding: 0,
              '@media print': {
                breakAfter: 'avoid',
                borderRadius: 0,
              },
            }}
          >
            <Box sx={{ p: '10mm' }}>
              <Typography
                variant="h4"
                color="darkgreen"
                textAlign="center"
                gutterBottom
                sx={{ fontWeight: 'bold' }}
              >
                BIOSECURITY MANAGEMENT AREA/S
              </Typography>

              <Typography
                variant="body2"
                paragraph
                sx={{ fontStyle: 'italic' }}
              >
                Instruction: Draw a diagram of the property (or insert a map,
                photo or plan) (mandatory). Clearly outline the boundaries of
                and identify all access points (main vehicle access points, or
                all pedestrian access points if not possible) to the biosecurity
                management area/s.
              </Typography>

              <Box
                sx={{
                  border: '1px solid black',
                  height: '600px',
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  position: 'relative',
                }}
                onClick={handleOpen}
              >
                <FormMap initialGeoJson={savedGeoJson} />
                {!savedGeoJson && (
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      color: 'darkgreen',
                      fontSize: '24px',
                      fontWeight: 'bold',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      padding: '10px 20px',
                      borderRadius: '4px',
                      zIndex: 1000,
                    }}
                  >
                    Click here
                  </Typography>
                )}
              </Box>

              <TableContainer>
                <Table sx={{ border: '4px solid darkgreen' }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          width: '50%',
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Boundaries of biosecurity management area/s identified
                        by: (e.g. dotted line, coloured line)
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                          height: '100px',
                          position: 'relative',
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: '12px',
                            color: 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          _________________________
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{
                          border: '1px solid darkgreen',
                          fontWeight: 'bold',
                          bgcolor: '#e0e0e0',
                        }}
                      >
                        Access points to biosecurity management area/s
                        identified by: (e.g. cross, circle)
                      </TableCell>
                      <TableCell
                        sx={{
                          border: '1px solid darkgreen',
                          bgcolor: '#f0f0f0',
                          height: '100px',
                        }}
                      >
                        <img
                          src="https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png"
                          alt="PinDropIcon"
                          style={{ width: '20px', height: '35px' }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>

          {/* Updated Print Button - moved inside the form container */}
          <Box
            sx={{
              position: 'sticky',
              bottom: 16,
              display: 'flex',
              justifyContent: 'flex-end',
              '@media print': {
                display: 'none',
              },
              zIndex: 3000,
            }}
          >
            <Fab color="primary" aria-label="print" onClick={handlePrint}>
              <PrintIcon />
            </Fab>
          </Box>
        </Box>
      </ThemeProvider>
      <NewSite open={open} onClose={handleClose} onCreate={handleCreateSite} />
    </Container>
  );
}
