import React, { useState, useRef } from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Box,
  Link,
} from '@mui/material';
import { Autocomplete } from '@react-google-maps/api'; // Import Autocomplete from @react-google-maps/api

interface SignupProps {
  email: string;
  password: string;
  name: string;
  phoneNumber: string;
  address: string;
  signedTermsAndConditions: boolean;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  setName: (value: string) => void;
  setPhoneNumber: (value: string) => void;
  setAddress: (value: string) => void;
  signup: () => void;
  setSignedTermsAndConditions: (value: boolean) => void;
}

const Signup: React.FC<SignupProps> = ({
  email,
  password,
  name,
  phoneNumber,
  address,
  signedTermsAndConditions,
  setEmail,
  setPassword,
  setName,
  setPhoneNumber,
  setAddress,
  signup,
  setSignedTermsAndConditions,
}) => {
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(emailRegex.test(email) ? null : 'Invalid email format');
    return emailRegex.test(email);
  };

  const signupChecks = async () => {
    const emailValid = validateEmail(email);
    const passwordValid = validatePassword();
    const termsValid = signedTermsAndConditions;

    if (emailValid && passwordValid && termsValid) {
      setIsLoading(true);
      await signup();
      setIsLoading(false);
    } else if (!termsValid) {
      alert('You must accept the terms and conditions to sign up.');
    }
  };

  function getAppUrl() {
    const url = window.location.href;
    return url;
  }

  const termsUrl = getAppUrl() + '/termsandconditions.html';

  const validatePassword = () => {
    const isValid =
      password.length >= 8 &&
      /\d/.test(password) &&
      /[!@#$%^&*]/.test(password);
    console.log(isValid);
    setPasswordError(
      isValid
        ? null
        : 'Password must be at least 8 characters long and include a number and a special character'
    );
    return isValid;
  };

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.formatted_address) {
        setAddress(place.formatted_address);
      }
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        p: 3,
        border: '1px solid #E5E5E5',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(128, 128, 128, 0.7)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Sign up is disabled
        </Typography>
      </Box>

      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Box
          component="img"
          src="/exoflare_tech.png"
          alt="ExoFlare Logo"
          sx={{
            height: '60px',
            width: 'auto',
            display: 'block',
            margin: 'auto',
            marginBottom: '20px',
            marginTop: '40px',
          }}
        />
      </Box>

      <Typography
        variant="h6"
        sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}
      >
        Create your account
      </Typography>

      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        {/* Email Field */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            alignItems: 'flex-start',
          }}
        >
          <Typography
            component="label"
            htmlFor="email"
            sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
          >
            <Box component="span" sx={{ color: 'error.main' }}>
              *
            </Box>{' '}
            Email
          </Typography>
          <TextField
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
            fullWidth
            size="small"
          />
        </Box>

        {/* Password Field */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            alignItems: 'flex-start',
          }}
        >
          <Typography
            component="label"
            htmlFor="password"
            sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
          >
            <Box component="span" sx={{ color: 'error.main' }}>
              *
            </Box>{' '}
            Password
          </Typography>
          <TextField
            id="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
            fullWidth
            size="small"
          />
        </Box>

        {/* Name Field */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            alignItems: 'flex-start',
          }}
        >
          <Typography
            component="label"
            htmlFor="name"
            sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
          >
            <Box component="span" sx={{ color: 'error.main' }}>
              *
            </Box>{' '}
            Name
          </Typography>
          <TextField
            id="name"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
            size="small"
          />
        </Box>

        {/* Phone Number Field */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            alignItems: 'flex-start',
          }}
        >
          <Typography
            component="label"
            htmlFor="phone"
            sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
          >
            Phone Number
          </Typography>
          <TextField
            id="phone"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            fullWidth
            size="small"
          />
        </Box>

        {/* Address Field */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // gap: 0.5,
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Typography
            component="label"
            htmlFor="address"
            sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
          >
            Address
          </Typography>
        </Box>
        <Autocomplete
          onLoad={autocomplete => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceChanged}
        >
          <TextField
            id="address"
            value={address}
            onChange={e => setAddress(e.target.value)}
            fullWidth
            size="small"
            sx={{ width: '100%' }}
          />
        </Autocomplete>

        {/* Terms and Conditions */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <input
            type="checkbox"
            checked={signedTermsAndConditions}
            onChange={e => setSignedTermsAndConditions(e.target.checked)}
          />
          <Typography variant="body2">
            I accept the{' '}
            <Link href={termsUrl} target="_blank" rel="noopener noreferrer">
              terms and conditions
            </Link>
          </Typography>
        </Box>

        {/* Sign Up Button */}
        <Button
          variant="contained"
          onClick={signupChecks}
          disabled={isLoading}
          sx={{
            mt: 1,
            py: 1.5,
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
        >
          {isLoading ? 'Signing Up...' : 'Sign Up'}
        </Button>
      </Box>
    </Box>
  );
};

export default Signup;
