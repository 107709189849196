import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import NewSite from './NewSite'; // Import the new component

import { Agriculture } from '@mui/icons-material';

import { API_BASE_URL, SessionManager } from '../../utils/consts';
import { Feature } from 'geojson';
import axios from 'axios';
import SiteManager from './SiteManager';
import BMPManager from './BMPManager';

interface FarmSitesProps {
  sessionManager: SessionManager;
}

interface SiteData {
  id: number;
  name: string;
  address: string;
  geoJson: string;
  center: string;
  status: string;
  site_manager: string;
  site_manager_phone: string;
  site_manager_email: string;
}

export interface Site {
  id: number;
  name: string;
  address: string;
  geoJson: Feature;
  center: [number, number];
  status: string;
  site_manager: string;
  site_manager_phone: string;
  site_manager_email: string;
}

const FarmSites: React.FC<FarmSitesProps> = ({ sessionManager }) => {
  const [open, setOpen] = useState(false);
  const [sites, setSites] = useState<Site[]>([]);
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const [selectedSiteForBMPs, setSelectedSiteForBMPs] = useState<Site | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCreateSite = async (site: {
    name: string;
    address: string;
    site_manager: string;
    site_manager_phone: string;
    site_manager_email: string;
    geoJson: Feature;
  }) => {
    // Logic to create a new site
    console.log('Creating site:', site);

    // post to /create_site
    try {
      const token = sessionManager.getToken();
      await axios.post(
        `${API_BASE_URL}/create_site`,
        {
          name: site.name,
          address: site.address,
          site_manager: site.site_manager,
          site_manager_phone: site.site_manager_phone,
          site_manager_email: site.site_manager_email,
          geoJson: site.geoJson,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error creating site:', error);
    }

    handleClose();
    getSites();
  };

  function getSites() {
    setLoading(true);
    sessionManager.getData('user_sites').then((data: SiteData[]) => {
      console.log(data);
      if (!data) {
        setLoading(false);
        return;
      }

      const convertedData = data.map(site => ({
        ...site,
        geoJson: JSON.parse(site.geoJson),
        center: [
          parseFloat(
            site.center.replace('(', '').replace(')', '').split(',')[0].trim()
          ),
          parseFloat(
            site.center.replace('(', '').replace(')', '').split(',')[1].trim()
          ),
        ] as [number, number],
      }));
      setSites(convertedData);
      console.log(convertedData);
      setLoading(false);
    });
  }

  useEffect(() => {
    getSites();
  }, []);

  const exitAndRefresh = () => {
    getSites();
    setSelectedSite(null);
    setSelectedSiteForBMPs(null);
  };

  return (
    <div>
      {selectedSiteForBMPs ? (
        <BMPManager
          sessionManager={sessionManager}
          site={selectedSiteForBMPs}
          exitAndRefresh={exitAndRefresh}
        />
      ) : selectedSite ? (
        <SiteManager
          sessionManager={sessionManager}
          site={selectedSite}
          exitAndRefresh={exitAndRefresh}
        />
      ) : (
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <div></div>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginBottom: '16px', marginTop: '16px' }}
            >
              My Farm Sites
            </Typography>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              New Site
            </Button>
          </Box>
          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : sites.length === 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              my={4}
            >
              <Box
                sx={{
                  backgroundColor: 'rgba(25, 118, 210, 0.08)', // light blue background
                  borderRadius: '50%',
                  padding: 3,
                  marginBottom: 2,
                }}
              >
                <Agriculture
                  sx={{
                    fontSize: 80,
                    color: 'primary.main',
                  }}
                />
              </Box>
              <Typography variant="h6" color="primary" fontWeight="bold">
                You have no sites yet
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Create a new site by adding your farm details
              </Typography>
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sites.map(site => (
                    <TableRow key={site.id}>
                      <TableCell>{site.name}</TableCell>
                      <TableCell>{site.address}</TableCell>
                      <TableCell>{site.status}</TableCell>
                      {/* Assuming status is always 'Active' for now */}
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() => setSelectedSite(site)}
                        >
                          Edit
                        </Button>
                        <span style={{ marginRight: '8px' }}></span>
                        <Button
                          variant="outlined"
                          onClick={() => setSelectedSiteForBMPs(site)}
                        >
                          Biosecurity Management Plans
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <NewSite
            open={open}
            onClose={handleClose}
            onCreate={handleCreateSite}
          />
        </Container>
      )}
    </div>
  );
};

export default FarmSites;
