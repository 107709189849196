import {
  MapContainer,
  TileLayer,
  GeoJSON,
  CircleMarker,
  Polygon,
  LayersControl,
  WMSTileLayer,
  Popup,
} from 'react-leaflet';
import { FeatureCollection } from 'geojson';
import L, { LatLngExpression } from 'leaflet';
import { useEffect, useState } from 'react';
import { SessionManager } from '../../utils/consts';

interface MapProps {
  sessionManager: SessionManager;
  paddocks: FeatureCollection | null;
  zones: FeatureCollection | null;
  observations: FeatureCollection | null;
  waterBirds: FeatureCollection | null;
  onPolygonClick: (feature: any, event: L.LeafletMouseEvent) => void;
}

export interface ProfileProps {
  email: string;
  id: number;
  name: string;
  phone_number: string;
  address: string;
  location: string;
}

const Map: React.FC<MapProps> = ({
  sessionManager,
  paddocks,
  zones,
  observations,
  waterBirds,
  onPolygonClick,
}) => {
  const [profile, setProfile] = useState<ProfileProps | null>(null);
  const getProfile = async () => {
    const responseData = await sessionManager.getData('profile');
    setProfile(responseData);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div
      id="map"
      style={{ height: '100vh', width: '100vw', position: 'absolute' }}
    >
      <MapContainer
        center={[-33.808, 150.937]}
        zoom={11}
        style={{ height: '100%', width: '100%' }}
      >
        <LayersControl position="topleft">
          <LayersControl.BaseLayer checked name="Satellite">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Map">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>

          {paddocks && (
            <>
              <GeoJSON
                data={paddocks}
                onEachFeature={(feature, layer) => {
                  layer.on({
                    click: (event: L.LeafletMouseEvent) =>
                      onPolygonClick(feature, event),
                  });
                }}
              />
              {paddocks.features.map((feature, index) => {
                const centroid = feature.properties?.centroid.coordinates;
                const is_exoflare = feature.properties?.is_exoflare;
                if (is_exoflare) {
                  return (
                    <CircleMarker
                      key={index}
                      center={[centroid[1], centroid[0]]}
                      radius={2}
                      color="orange"
                      fillColor="orange"
                      fillOpacity={1}
                    >
                      <Popup>
                        <a
                          href={`https://www.google.com/maps?q=${centroid[1]},${centroid[0]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View on Google Maps
                        </a>
                      </Popup>
                    </CircleMarker>
                  );
                }

                return (
                  <CircleMarker
                    key={index}
                    center={[centroid[1], centroid[0]]}
                    radius={1}
                    color="blue"
                    fillColor="blue"
                    fillOpacity={1}
                  >
                    <Popup>
                      <a
                        href={`https://www.google.com/maps?q=${centroid[1]},${centroid[0]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View on Google Maps
                      </a>
                    </Popup>
                  </CircleMarker>
                );
              })}
            </>
          )}
          {zones &&
            zones.features.map((feature, index) => {
              if (feature.geometry.type === 'Polygon') {
                // @ts-ignore
                const positions_reversed: [number, number][] =
                  feature.geometry.coordinates[0];
                console.log(positions_reversed);
                const positions = positions_reversed.map(
                  (coord: [number, number]) => [coord[1], coord[0]]
                ) as LatLngExpression[];
                return (
                  <Polygon
                    key={index}
                    positions={positions}
                    color={feature.properties?.fill}
                    fillColor={feature.properties?.fill}
                    fillOpacity={0.2}
                  />
                );
              }
              return null;
            })}
          {observations &&
            observations.features.map((feature, index) => {
              if (feature.geometry.type === 'Point') {
                const coordinates = feature.geometry.coordinates;
                return (
                  <CircleMarker
                    key={index}
                    center={[coordinates[1], coordinates[0]]}
                    radius={4}
                    color="red"
                    fillColor="red"
                    fillOpacity={0.8}
                  >
                    <Popup>
                      {feature.properties &&
                        Object.entries(feature.properties).map(
                          ([key, value]) => (
                            <div key={key}>
                              <strong>{key}</strong>: {value}
                            </div>
                          )
                        )}
                    </Popup>
                  </CircleMarker>
                );
              }
              return null;
            })}
          {waterBirds &&
            waterBirds.features.map((feature, index) => {
              if (feature.geometry.type === 'Point') {
                const coordinates = feature.geometry.coordinates;
                return (
                  <CircleMarker
                    key={index}
                    center={[coordinates[1], coordinates[0]]}
                    radius={3}
                    color="green"
                    fillColor="green"
                    fillOpacity={0.8}
                  />
                );
              }
              return null;
            })}
          {profile && profile.location && (
            <CircleMarker
              center={
                profile.location.split(',').map(Number) as [number, number]
              }
              radius={5}
              color="purple"
              fillColor="purple"
              fillOpacity={0.8}
            />
          )}
        </LayersControl>
      </MapContainer>
    </div>
  );
};

// Function to determine color based on sand percentage
const getSandColor = (sandPercentage: number) => {
  return sandPercentage > 75
    ? '#d73027'
    : sandPercentage > 50
      ? '#fc8d59'
      : sandPercentage > 25
        ? '#fee08b'
        : '#d9ef8b';
};

interface WMSLayerProps {
  url: string;
  layers: string;
  format?: string;
  transparent?: boolean;
  version?: string;
  crs?: L.CRS;
  attribution?: string;
  bounds?: [[number, number], [number, number]];
}

const GenericWMSLayer: React.FC<WMSLayerProps> = ({
  url,
  layers,
  format = 'image/png',
  transparent = true,
  version = '1.1.1',
  crs = L.CRS.EPSG3857,
  attribution = '',
  bounds,
}) => (
  <WMSTileLayer
    url={url}
    layers={layers}
    format={format}
    transparent={transparent}
    version={version}
    crs={crs}
    attribution={attribution}
    bounds={bounds}
  />
);

export default Map;
