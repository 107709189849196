import { useState } from 'react';
import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Paper,
  Typography,
  Button,
  Alert,
} from '@mui/material';
import PetsIcon from '@mui/icons-material/Pets';

export const Questions: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleOptionChange = (option: string) => {
    setSelectedOptions(prev => {
      if (prev.includes(option)) {
        // If "None of the above" is being unchecked, just remove it
        if (option === 'none') {
          return prev.filter(item => item !== option);
        }
        // If a regular option is being unchecked, remove it
        const newSelection = prev.filter(item => item !== option);
        // If no other options remain except possibly "none", keep/add "none"
        if (newSelection.filter(item => item !== 'none').length === 0) {
          return ['none'];
        }
        return newSelection;
      } else {
        // If "None of the above" is being checked, return only "none"
        if (option === 'none') {
          return ['none'];
        }
        // If a regular option is being checked, remove "none" if it exists
        const newSelection = [...prev.filter(item => item !== 'none'), option];
        return newSelection;
      }
    });
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={1} sx={{ p: 3 }}>
        <Typography variant="h6" color="primary" gutterBottom>
          Pig and avian contact
        </Typography>

        <Box sx={{ mb: 4 }}>
          <LinearProgress variant="determinate" value={20} sx={{ mb: 1 }} />
          <Typography variant="body2" color="text.secondary">
            1 of 5
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          Which of the following have you done in the 3 days (72 hours) before
          this visit?
        </Typography>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          Select all that apply
        </Typography>

        <FormControl component="fieldset" sx={{ width: '100%', my: 2 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.includes('birds')}
                  onChange={() => handleOptionChange('birds')}
                  disabled={selectedOptions.includes('none')}
                />
              }
              label="Had contact with birds or visited areas where poultry are roaming, housed, handled or processed*"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.includes('pigs')}
                  onChange={() => handleOptionChange('pigs')}
                  disabled={selectedOptions.includes('none')}
                />
              }
              label="Had contact with pigs or visited areas where pigs are roaming, housed, handled or processed*"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.includes('manure')}
                  onChange={() => handleOptionChange('manure')}
                  disabled={selectedOptions.includes('none')}
                />
              }
              label="Used untreated poultry or pig manure from other properties at your place of residence"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.includes('none')}
                  onChange={() => handleOptionChange('none')}
                  disabled={
                    selectedOptions.length > 0 &&
                    !selectedOptions.includes('none')
                  }
                />
              }
              label="None of the above"
            />
          </FormGroup>
        </FormControl>

        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          * For example: hunting, agricultural shows, farms, processing plants,
          and laboratories
        </Typography>

        <Alert
          severity="warning"
          icon={<PetsIcon />}
          sx={{ mb: 3, backgroundColor: '#ffebee', color: '#d32f2f' }}
        >
          Declare any contact with pigs or birds (for example: poultry,
          ostriches, aviary birds, or pigeons) as this can bring disease onto
          the farm.
        </Alert>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedOptions.length === 0}
          >
            Next
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};
